@use "sass:math";
@use "../colors" as hubertColors;
@use "vars" as vars;

$primary-header-color: set-button-text-color(hubertColors.$gray-800);
$primary-color: set-button-text-color(vars.$chat-color);
$bodil-primary-color: set-button-text-color(hubertColors.$white);

.hidden {
  display: none !important;
}

.interview-container {
  position: fixed;
  width: 100% !important;
  min-width: 350px !important;
  max-width: 600px !important;
  border-radius: var(--element-radius) !important;
  inset: 0;

  @media (width >= 425px) {
    .interview-container {
      height: vars.$height;
      max-height: 800px;
    }
  }
}

.reusables-container {
  @apply flex flex-col gap-6;
  @apply my-8 mx-0 md:mx-8;
  @apply p-6;
  @apply bg-white rounded-2xl;
  @apply border border-solid border-gray-300;

  @supports (text-wrap: balance) {
    text-wrap: balance;
  }

  .reusables-header {
    @apply text-center mb-5;
  }

  .reusable-single-select,
  .reusable-open,
  .reusable-date {
    @apply bg-primary-neutral;
    @apply border border-solid border-primary;
    @apply p-4;
    @apply rounded-2xl;
    @apply text-center;
  }

  .reusable-single-select {
    .question-wrapper {
      margin-bottom: 1rem;
    }
  }

  .reusable-date {
    .question-wrapper {
      margin-bottom: 1.5rem;
    }
  }

  .save-reuse-button {
    @apply flex justify-center items-center text-base;
    @apply bg-gray-900 text-white;

    &:hover {
      @apply scale-100;
    }
  }

  .input-bubble {
    @apply mx-auto text-center text-sm p-2;
    @apply bg-gray-900 hover:bg-primary-dark;
    @apply transition-transform ease-in duration-150;
    @apply scale-100;

    border-radius: calc(var(--element-radius) / 2);
    color: $primary-header-color;
    -webkit-text-fill-color: $primary-header-color;

    &:hover {
      @apply scale-105;

      color: $primary-header-color;
    }

    & input:invalid {
      @apply border-[3px] border-danger;
    }

    &.clicked {
      @apply bg-primary;

      &:hover {
        @apply bg-primary-dark;
      }
    }
  }
}
