.matrix {
  @apply min-w-full md:min-w-[400px] overflow-x-scroll mb-4 overflow-scroll;
  @apply flex flex-col;
  @apply gap-y-1 pb-4;
}

.matrixRow {
  @apply grid items-stretch;

  grid-template-columns: repeat(var(--column-count, 3), minmax(50px, 1fr));
  padding-block: 0.5em;

  &.verticalCenter {
    @apply items-center;
  }

  p:not(.rowLabel) {
    @apply inline-block align-bottom;

    font-weight: 600;
    text-align: center;
  }

  input[type="radio"] {
    @apply w-fit justify-self-center;
  }

  div:first-of-type {
    @apply w-full;
  }

  :is(.rowLabel, div):first-of-type {
    @apply sticky top-0 left-0;
    @apply bg-primary-neutral;
  }
}
