.container {
  @apply flex flex-col items-center gap-y-1;

  span {
    @apply text-xs pl-1 md:pl-0;
  }
}

.preInterviewInput {
  --border-color: var(--color-primary-extralight);
  --outline-color: var(--color-primary);

  @apply w-full;
  @apply py-4 rounded-2xl;
  @apply border;
  @apply px-4 md:px-0 md:text-center;

  border-color: var(--border-color);

  &:focus,
  &:focus-within {
    outline-color: var(--outline-color);
  }

  &.error {
    --border-color: var(--color-danger-light);
    --outline-color: var(--color-danger);

    ~ .errorMessage {
      @apply text-danger;
    }
  }

  &:disabled {
    --border-color: var(--color-gray-300);
    --outline-color: var(--color-gray-400);

    @apply text-gray-400;
  }

  &[class~="text-left"] {
    @apply text-left px-4;
  }
}
