/* stylelint-disable selector-class-pattern */

.hubert-datepicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  .react-datepicker,
  .react-datepicker__header {
    @apply bg-primary-neutral;
  }

  .react-datepicker {
    @apply border-solid border border-primary;

    .react-datepicker__header {
      @apply border-b-0 pt-1;
    }

    .react-datepicker__month-container {
      @apply w-full;
    }

    .react-datepicker__navigation-icon::before {
      @apply border-gray-500 border-solid border-t-2 border-r-2;
    }

    .react-datepicker__day {
      &.react-datepicker__day--today {
        --bs-border-color: var(--color-primary-light);

        @apply border rounded-md;
      }

      &.react-datepicker__day--outside-month {
        --value-opacity: 0.5;

        &:not(.react-datepicker__day--weekend) {
          --value-opacity: 0.35;

          opacity: var(--value-opacity);
        }

        &.react-datepicker__day--weekend {
          --bs-text-opacity: var(--value-opacity);
        }
      }

      &.react-datepicker__day--selected {
        @apply bg-primary-light text-primary-neutral;
      }

      &.react-datepicker__day--weekend {
        @apply text-danger;

        &.react-datepicker__day--selected {
          @apply text-white;
        }
      }
    }

    .react-datepicker__day-names {
      @apply border-t border-b border-gray-300 mt-1.5 mx-2;

      .react-datepicker__day {
        &.react-datepicker__day--outside-month {
          @apply text-opacity-50 opacity-50;
        }
      }

      .react-datepicker__day-name:nth-last-of-type(-n + 2) {
        @apply text-danger;
      }
    }
  }
}
