@keyframes typing {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.hubertTyping {
  display: flex;
  margin-block: 0.25rem 1.5rem;

  [class~="chat-bubble"] {
    @apply flex items-start;

    [class~="bubble-content"] {
      @apply rounded-3xl px-3 py-2 bg-gray-100 transition-all duration-300 ease-out;

      border-bottom-left-radius: 0;
    }
  }

  .hubertAvatar {
    @apply w-6 h-6 rounded-full;
    @apply self-end flex justify-center items-center;
    @apply mr-2;
    @apply bg-gray-900 text-white;
    @apply text-xs leading-none font-semibold;

    position: relative;
    top: 5px;
  }

  .circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: #757575;

    &.scaling {
      animation: typing 1000ms ease-in-out infinite;
      animation-delay: 3600ms;
    }

    &.bouncing {
      animation: bounce 1000ms ease-in-out infinite;
      animation-delay: 3600ms;
    }

    &:nth-child(1) {
      animation-delay: 0ms;
    }

    &:nth-child(2) {
      animation-delay: 333ms;
    }

    &:nth-child(3) {
      animation-delay: 666ms;
    }
  }
}
