.AlertDialogOverlay {
  @apply bg-gray-900 opacity-75;
  @apply fixed inset-0;

  animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.AlertDialogContent {
  @apply bg-white rounded-md;
  @apply fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  @apply p-6;
  @apply overflow-y-scroll;

  box-shadow: hsl(206deg 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206deg 22% 7% / 20%) 0 10px 20px -15px;
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  animation: content-show 150ms cubic-bezier(0.16, 1, 0.3, 1);

  h1,
  h2,
  h3,
  h4,
  strong {
    @apply my-4;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }
}

.AlertDialogContent:focus {
  @apply outline-none;
}

.AlertDialogTitle {
  @apply m-0;
  @apply text-gray-900 text-xl font-semibold;
}

.AlertDialogClose {
  @apply sticky top-0 right-0 z-20 float-right bg-black rounded-full text-white p-1;
  @apply transition-transform ease-in-out duration-100;

  &:hover {
    @apply scale-110;
  }
}

.AlertDialogDescription {
  @apply mb-6;
  @apply text-gray-900 text-base leading-6;
}

@keyframes overlay-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes content-show {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media (prefers-reduced-motion: reduce) {
  .AlertDialogOverlay {
    animation: none;
  }

  .AlertDialogContent {
    animation: none;
  }
}
