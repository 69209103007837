.form-container {
  @apply flex flex-col justify-center gap-1;
  @apply mt-5 py-3 px-5 bg-primary-neutral border border-primary-light mx-auto rounded-xl;
  @apply mb-4;

  // Try to swap with container queries when support comes in vscode
  @apply w-full sm:max-w-md;

  .form-label-wrapper {
    @apply mb-2;
  }

  .form-error-wrapper {
    @apply mt-2;
  }
}
