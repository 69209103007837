.overview-section-wrapper {
  @apply flex pt-12 pb-6 items-center gap-4 max-w-sm mx-auto;

  .logo-wrapper {
    @apply h-[60px] w-[60px] bg-white rounded-xl flex justify-center items-center;

    img {
      @apply h-12 w-12 bg-cover;
    }

    .company-letter {
      @apply text-2xl leading-none;
    }
  }

  .content-wrapper {
    @apply text-gray-400 flex-1;

    .title {
      @apply text-white;
    }
  }
}
