.btn {
  --bg-color: var(--color-primary);
  --text-color: var(--color-primary-text);
  --icon-stroke-color: var(--color-primary-text);
  --border-color: transparent;

  @apply h-9;
  @apply px-4 py-6 md:py-4 lg:py-0;
  @apply flex justify-center items-center gap-2;
  @apply rounded-md;

  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  transition: all ease-in-out 150ms;

  svg {
    @apply w-4 h-4 text-white;

    stroke: var(--icon-stroke-color);
  }

  .outlined {
    --border-color: transparent;

    transition: border-color ease-in-out 0.2s;
  }

  &:disabled {
    --bg-color: var(--color-gray-200);
    --text-color: var(--color-gray-400);
    --border-color: var(--color-gray-200);

    svg {
      stroke: var(--color-primary-dark);
    }

    &:hover {
      --bg-color: var(--color-gray-200);
      --text-color: var(--color-gray-400);
      --border-color: var(--color-gray-200);

      cursor: not-allowed;
    }
  }
}

.iconBtn {
  @apply aspect-square;
  @apply rounded-full;

  svg {
    @apply w-5 h-5;
  }
}

.btnPrimary {
  --bg-color: var(--color-primary);
  --text-color: var(--color-primary-text);
  --border-color: var(--color-primary);

  svg {
    --icon-stroke-color: var(--color-primary-text);
  }

  &:hover {
    --bg-color: var(--color-primary-dark);
    --text-color: var(--color-primary-text);
    --icon-stroke-color: var(--color-primary-text);
  }

  &.outlined {
    --bg-color: transparent;
    --text-color: var(--color-primary);
    --border-color: var(--color-primary);

    &:hover {
      --border-color: var(--color-primary);
    }

    svg {
      --icon-stroke-color: var(--color-primary);
    }
  }
}

.btnDanger {
  @apply bg-danger text-white;

  &:hover {
    @apply bg-danger-dark text-white;
  }
}

.btnNeutral {
  --bg-color: var(--color-gray-800);
  --text-color: var(--color-white);

  @apply outline outline-transparent;
  @apply transition-all duration-150;

  &:hover {
    --bg-color: var(--color-gray-900);
  }

  &.outlined {
    --bg-color: transparent;
    --text-color: var(--color-gray-900);
    --border-color: var(--color-gray-900);

    &:hover {
      --bg-color: var(--color-gray-200);
    }
  }
}
