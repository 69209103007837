@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";
@use "./fonts";
@use "./colors";
@use "./texts";
@use "./buttons";
@use "./normalize";

@layer base {
  :root {
    // Primitive Colors
    --color-white: #fff;
    --color-black: #000;
    --color-gray-50: #f9fafb;
    --color-gray-100: #f3f4f6;
    --color-gray-200: #e5e7eb;
    --color-gray-300: #d1d5db;
    --color-gray-400: #9ca3af;
    --color-gray-500: #6b7280;
    --color-gray-600: #4b5563;
    --color-gray-700: #374151;
    --color-gray-800: #2b2e33;
    --color-gray-900: #15171a;

    // Theme Colors
    // Used for Primary Elements like Buttons and Links
    --color-primary: hsl(255deg 100% 65%);
    --color-primary-text: var(--color-white);

    // Used as active states of primary elements to indicate focus and hover states
    --color-primary-dark: hsl(256deg 59% 51%);

    // Used as accent to primary elements
    --color-primary-light: hsl(256deg 100% 73%);
    --color-primary-extralight: hsl(256deg 100% 85%);
    --color-neutral-blue: hsl(210deg 20% 98%);
    --color-primary-neutral: hsl(249deg 100% 97%);

    // Status Colors
    --color-success: #20c997;
    --color-success-dark: #229a77;
    --color-success-light: #5eefc4;
    --color-info: #3194ff;
    --color-info-dark: #0068d8;
    --color-info-light: #6eb3ff;
    --color-warning: #ffc107;
    --color-warning-dark: #ffa800;
    --color-warning-light: #ffd65a;
    --color-danger: #f95f5f;
    --color-danger-dark: #d44a4a;
    --color-danger-light: #ff8484;

    // Defaults
    --min-chat-height: 85dvh;

    // Non-specific element radius
    --element-radius: 8px;
    --input-field-radius: 8px;
    --input-field-radius-sm: 4px;

    // Component Specific
    --open-question-input-height: 80px;
    --chat-header-min-height: 65px;

    /* Fonts */
    --font-suisse: "Suisse Int'l", sans-serif;
  }

  a {
    @apply underline underline-offset-4;
  }
}

body {
  font-family: "Suisse Int'l", sans-serif !important;

  @apply text-gray-900 font-normal h-full;
}

@layer utilities {
  .scrollbar-hide {
    /* Safari and Chrome */
    &::-webkit-scrollbar {
      width: 0;
    }

    &:where(
        ::-webkit-scrollbar,
        ::-webkit-scrollbar-track,
        ::-webkit-scrollbar-thumb
      ) {
      display: "none";
      appearance: none;
    }

    @supports (scrollbar-width: none) {
      /* Firefox */
      scrollbar-width: "none";
    }

    @supports (scrollbar-color: transparent transparent) {
      scrollbar-color: transparent transparent;
    }
  }
}
