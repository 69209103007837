//////// NEW OLD
@use "../colors" as hubertColors;
@use "sass:math";
@use "vars" as vars;

.show {
  transform: translateY(0);
}

.hide {
  transform: translateY(230%);
}

.chat-widget-launcher {
  @apply transition duration-75;
  @apply fixed flex border-none;
  @apply cursor-pointer;

  z-index: 10002;
  right: vars.$icon-right;
  bottom: vars.$icon-bottom;
  width: vars.$icon-size;
  height: vars.$icon-size;
  border-radius: vars.$icon-size;
  border-bottom-right-radius: 0;
  color: vars.$primary-color;

  @if vars.$icon-color {
    background-color: var(--color-white);
  } @else {
    background-color: var(--color-gray-800);
  }
}

.chat-widget {
  @apply fixed flex;
  @apply border-none;

  z-index: 10002;
  right: vars.$icon-right;
  bottom: vars.$icon-bottom;
  width: vars.$icon-size;
  height: vars.$icon-size;
  border-radius: vars.$icon-size;
  border-bottom-right-radius: 0;
  color: vars.$primary-color;
  transition: vars.$transition;

  @if vars.$icon-color {
    background-color: var(--color-white);
  } @else {
    background-color: var(--color-gray-800);
  }
}

#chat-widget-launcher {
  @apply mx-auto rotate-0 p-4;

  box-shadow: 0 0 25px 0 rgb(0 0 0 / 75%);

  .question {
    @if vars.$icon-color {
      color: set-button-text-color(vars.$icon-color) !important;
      -webkit-text-fill-color: set-button-text-color(
        vars.$icon-color
      ) !important;
    } @else {
      color: $primary-header-color !important;
      -webkit-text-fill-color: $primary-header-color !important;
    }
  }

  &.show {
    @apply -rotate-90;

    .question {
      @if vars.$icon-color {
        color: set-button-text-color(hubertColors.$white) !important;
        -webkit-text-fill-color: set-button-text-color(
          hubertColors.$white
        ) !important;
      } @else {
        color: $primary-header-color !important;
        -webkit-text-fill-color: $primary-header-color !important;
      }
    }
  }

  &.hidden {
    @apply invisible;

    + #bubble-container {
      bottom: vars.$margin !important;
    }
  }

  @media screen and (width <= 425px) {
    right: 8px !important;
    bottom: 75px;

    &.show {
      right: 10px;
      bottom: 10px !important;
      width: 50px;
      height: 50px;
      padding: 0;
      border-radius: 25px;
    }
  }
}

#input-text {
  &::placeholder {
    @apply italic;

    text-transform: unset;
  }
}

.message-time {
  display: grid;
  margin-left: 2px;
  font-size: 12px;
  grid-template-rows: 0fr;
  transition: grid-template-rows cubic-bezier(0.47, 0, 0.745, 0.715) 0.15s;

  span {
    @apply overflow-y-hidden;
  }

  &.active {
    grid-template-rows: 1fr;
  }
}

.candidate-messages-wrapper {
  @apply flex flex-col justify-end;
}

.btn::after {
  content: "";
}

.q-btn {
  @apply mx-auto;

  max-width: 400px;
}

.unread-messages-notification {
  visibility: hidden;
}

.bubble-chat-box {
  @media screen and (width<=425px) {
    position: fixed;
    width: 100% !important;
    min-width: 350px !important;
    border-radius: var(--element-radius) !important;
    inset: 0;
  }
}
