:global(body p) {
  display: block;
}

.container {
  @apply flex flex-col md:items-center gap-8;
  @apply md:text-center;

  header h1 {
    @apply font-light text-3xl mb-0;
  }

  .intro {
    h1 {
      @apply text-2xl font-bold mb-2;
    }

    p {
      @apply block font-normal md:text-lg;
    }
  }

  form {
    min-width: 320px;
  }

  .progressContainer {
    @apply w-full flex justify-center;
  }

  .codeStatus {
    @apply flex flex-col items-center gap-4;
    @apply text-center;

    button {
      @apply text-primary underline;
      @apply w-fit;
    }
  }

  footer {
    @apply flex flex-col text-center gap-4 w-full md:w-3/4;
  }
}

.continueButton {
  @apply max-w-xs min-w-full;

  @screen md {
    min-width: 75%;
  }

  span {
    transition: margin-left 0.1s ease-in-out;
  }
}

.consentContainer {
  @apply py-4 rounded-2xl border;

  border: 1px solid black;
  background: white;
  min-width: 320px;
  max-width: 70vh;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 80%;
  min-height: 25rem;

  p {
    margin-bottom: 1rem;
  }
}

.infoText {
  @apply text-xs;

  color: var(--color-gray-600);
}

.info {
  @apply infoText;

  cursor: pointer;
  text-decoration: underline;
}
