@font-face {
  font-family: "Suisse Int'l";
  font-weight: 300;
  src: url("./../assets/fonts/SuisseIntl-Light.ttf");
}

@font-face {
  font-family: "Suisse Int'l";
  font-weight: 400;
  src: url("././../assets/fonts/SuisseIntl-Regular.ttf");
}

@font-face {
  font-family: "Suisse Int'l";
  font-weight: 450;
  src: url("././../assets/fonts/SuisseIntl-Book.otf");
}

@font-face {
  font-family: "Suisse Int'l";
  font-weight: 500;
  src: url("././../assets/fonts/SuisseIntl-Medium.otf");
}

@font-face {
  font-family: "Suisse Int'l";
  font-weight: 600;
  src: url("././../assets/fonts/SuisseIntl-SemiBold.ttf");
}
