.btn-sm {
  font-size: 10px !important;
}

table {
  table-layout: fixed;
}

.td-first {
  width: 50%;
  text-align: left;
}
