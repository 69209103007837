.progressBarWrapper {
  --progress-bar-height: 5px;

  height: var(--progress-bar-height);

  .progressBar {
    @apply w-full bg-gray-200;

    height: inherit;

    .filled {
      @apply bg-primary transition-all duration-300;

      &[data-progress] {
        width: attr(data-progress);
      }

      height: var(--progress-bar-height);
    }
  }
}
