.container {
  @apply grid grid-cols-4 gap-4;
}

.otpField {
  @apply h-20 md:w-[5.5rem] md:h-24;
  @apply text-2xl text-center;
  @apply border-2 border-primary-light rounded-md;
  @apply focus:outline-primary;

  appearance: none;

  &::-webkit-inner-spin-button {
    display: none;
  }

  &.error {
    @apply border-danger;
  }

  &:disabled {
    @apply bg-gray-50 border-gray-200;

    &::placeholder {
      @apply text-gray-300;
    }
  }
}

.pageError {
  @apply flex justify-center items-center gap-4;

  .iconContainer {
    @apply bg-danger text-white rounded-full w-fit p-1;
  }
}
