.container {
  @apply flex flex-col gap-y-1;

  .helperText {
    @apply text-helper text-gray-500;
  }

  &.error {
    .helperText.error {
      @apply text-danger;
    }
  }
}
