.chat-header-wrapper {
  @apply text-black bg-white md:rounded-t-[20px];
  @apply sticky top-0 z-10;

  box-shadow: 0 0 40px 0 #11182733;

  .content-wrapper {
    @apply p-3 flex items-center gap-4;

    min-height: var(--chat-header-min-height);

    .logo-wrapper {
      @apply relative h-10 w-10 bg-black rounded-full;
      @apply flex justify-center items-center;
      @apply aspect-square;

      .letter {
        @apply h-4 text-xl leading-none text-white;
      }

      &::after {
        content: "";

        @apply box-content absolute h-[10px] w-[10px] right-[-2px] bottom-0 bg-success rounded-full border-[2px] border-white;
      }
    }

    .title-wrapper {
      .online {
        @apply text-gray-500 leading-none;
      }
    }
  }
}
