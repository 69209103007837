.openQuestionForm {
  @apply sticky bottom-0;
  @apply flex items-center gap-4;
  @apply w-full;
  @apply bg-white;
  @apply px-5 py-1;
  @apply rounded-[20px] border-0;
  @apply duration-75;

  height: clamp(var(--open-question-input-height), fit-content, 10rem);
  border-bottom-left-radius: var(--element-radius);

  &.hide,
  &::-webkit-resizer {
    @apply hidden;
  }
}

.textarea {
  @apply resize-none rounded-3xl;
  @apply mr-2;
}
