.btn {
  @apply font-normal rounded-3xl leading-normal duration-200;

  &.back-button {
    .button-content-wrapper {
      @apply px-0;
    }
  }

  .button-content-wrapper {
    @apply flex justify-center items-center py-2.5 px-5;
  }

  .button-img-icon {
    @apply w-4 mr-2;
  }
}

.btn {
  @apply bg-primary hover:bg-primary-dark text-white;

  &:disabled {
    pointer-events: none;

    @apply bg-gray-300 hover:bg-gray-200 text-gray-500 border border-gray-400;
  }

  &.btn-secondary {
    &:disabled {
      pointer-events: none;

      @apply bg-gray-300 hover:bg-gray-200 text-gray-500 border border-gray-400;
    }

    @apply bg-white hover:bg-gray-200 text-gray-900 border border-gray-900;
  }
}

.btn-sm {
  @apply text-sm;

  .button-content-wrapper {
    @apply py-1.5 px-3;
  }
}
