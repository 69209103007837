.formGroup {
  @apply flex flex-col;
  @apply mt-2 mb-4;
}

.inputField {
  border-radius: var(--input-field-radius);

  @apply font-suisse;
  @apply align-baseline;
  @apply border border-solid border-gray-300 resize-none;
  @apply focus:border-primary focus-within:border-primary;
  @apply py-2.5 px-4 overflow-hidden;
  @apply min-h-[1.5rem];
  @apply w-full;

  &::-webkit-resizer {
    @apply hidden;
  }

  &:focus-visible {
    @apply outline-none ring-1 ring-primary-light;
  }

  &::placeholder {
    font-style: normal !important;
  }

  &:disabled {
    @apply bg-gray-200;
  }
}

.fieldLabel {
  @apply flex;
  @apply font-suisse text-gray-900;
}

.fieldError {
  @apply font-suisse text-sm text-danger;
  @apply mt-1;

  &.fieldAlignLeft {
    @apply text-left;
  }

  &.fieldAlignCenter {
    @apply text-center;
  }

  p {
    @apply mb-0;
  }
}
