@use "sass:math";
@use "sass:color";
@use "../colors" as hubertColors;
@use "../functions";

$chat-color: #d1ecf1;
$primary-bg-color: rgb(0 0 0 / 90%);
$primary-color: set-button-text-color($chat-color);
$bodil-primary-color: set-button-text-color(hubertColors.$white);
$primary-header-color: set-button-text-color($primary-bg-color);
$primary-dark-color: $primary-bg-color;
$chat-background-color: #f2f2f7;
$chat-color: #d1ecf1;
$text-input-color: #fff;
$margin: 20px;
$top-container-height: 80px;
$input-container-height: 80px;
$height: 600px;
$width: 400px;
$transition: 0.25s;
$border-thickness: 1px;
$border-color: color.adjust($primary-dark-color, $lightness: 25%);
$icon-size: 75px;
$icon-right: 25px;
$icon-bottom: 25px;
$profile-picture-size: 30px;
$bodil-profile-picture-url: url("https://hubert.ai/img/apple-icon-144x144.png");
$profile-picture-url: url("https://storage.googleapis.com/bodilweb_bucket/5f48fc8dcf8c165687af938c/profile/1598866690267");
$icon-color: #fff;
$profile-picture-header: url("https://hubert.ai/img/apple-icon-144x144.png");
