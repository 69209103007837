.react-tel-input {
  --border-color: var(--color-primary-extralight);
  --outline-color: transparent;

  @apply flex;
  @apply border border-solid;
  @apply rounded-2xl;

  border-color: var(--border-color);
  outline: 2px solid var(--outline-color);

  .flag-dropdown {
    pointer-events: none;
    border: none;
  }

  .form-control {
    all: unset;

    @apply w-full;
    @apply ml-6;
    @apply rounded-tr-2xl rounded-br-2xl;
    @apply p-4;
  }

  &:focus,
  &:focus-within {
    --outline-color: var(--color-primary);
  }
}
