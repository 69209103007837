.upload {
  @apply relative;
  @apply bg-gray-100;
  @apply rounded-lg;
  @apply flex items-center justify-start gap-6;
  @apply px-8 py-2;

  &:hover {
    @apply cursor-pointer;
    @apply bg-gray-200;

    .chooseFileText span:nth-of-type(2) {
      @apply underline;
    }
  }

  .body {
    @apply flex flex-col justify-center items-start gap-1;

    p {
      @apply text-left;
    }
  }

  .title {
    @apply text-sm text-black;
  }

  .chooseFileText {
    @apply text-sm;
  }

  .fileExtensions {
    @apply text-sm text-gray-400;
  }

  .removeFile {
    @apply ml-auto p-2 stroke-warning rounded-full;

    &:hover {
      @apply cursor-pointer bg-gray-300;
    }
  }
}

.label {
  @apply text-gray-500 font-medium;
}

.description {
  @apply text-gray-500 text-sm;
}
