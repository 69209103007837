.main {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    @apply text-4xl md:text-5xl my-0;
  }

  h2 {
    @apply text-2xl md:text-3xl;
  }

  p {
    font-size: 1rem;
  }
}

.errorContentWrapper {
  @apply flex flex-col gap-2;

  header {
    @apply flex flex-col gap-4;

    h2 {
      max-width: 24ch;
    }
  }

  .additionalDetails {
    @apply my-2;
    @apply flex flex-col gap-2;

    .details {
      @apply grid;
      @apply bg-gray-50;
      @apply px-1;

      grid-template-rows: 0fr;
      transition: grid-template-rows 0.3s ease-in-out;

      & > div {
        @apply overflow-hidden;
      }
    }

    &[data-expanded="true"] {
      .details {
        grid-template-rows: 1fr;
        padding-block: 0.5rem;
      }
    }

    button {
      @apply flex items-center hover:cursor-pointer;
    }
  }

  p {
    margin-bottom: 0.5em;
  }
}

.logo {
  position: fixed;
  bottom: 10px;
  width: clamp(100px, 10vw, 200px);
}
