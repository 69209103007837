#interview-intro {
  animation: center-to-top 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
  animation-delay: 2s;

  @apply flex flex-col items-center gap-4;
  @apply w-3/4 mx-auto;
  @apply my-12;
  @apply text-center;
  @apply transform translate-y-full;
}

@keyframes center-to-top {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

#container-rect {
  animation: move-container-to-left 1s cubic-bezier(0.785, 0.135, 0.15, 0.86)
    forwards;
  animation-delay: 2s;
}

.innerCircle {
  animation: increase-inner-circle-radius 1s
      cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards,
    move-container-to-left 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) 2s forwards;
}

.innerCircleShift {
  animation: move-circle-to-right 1s cubic-bezier(0.785, 0.135, 0.15, 0.86)
    forwards;
  animation-delay: 2s;
}

// #facial-features {
//   animation: scale-out-of-sight 1s cubic-bezier(0.785, 0.135, 0.15, 0.86)
//     forwards;
//   animation-delay: 2s;
//   transform-origin: center center;
// }

// @keyframes scale-out-of-sight {
//   from {
//     transform: scale(1);
//     opacity: 1;
//   }

//   to {
//     transform: scale(0);
//     opacity: 0;
//   }
// }

@keyframes move-container-to-left {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes move-circle-to-right {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(50%);
  }
}

@keyframes increase-inner-circle-radius {
  0% {
    r: 0;
  }

  100% {
    r: 19;
  }
}
