.textarea,
.hubertTextField {
  @apply font-suisse;

  &::placeholder {
    @apply font-suisse;
  }
}

.hubertTextField {
  min-height: 1.5rem;
  border-radius: var(--input-field-radius);
  box-sizing: border-box;

  @apply border border-solid resize-none;
  @apply py-2.5 px-4 overflow-hidden;

  &::-webkit-resizer {
    @apply hidden;
  }

  &:focus-visible {
    @apply outline-none ring-1 ring-primary-light;
  }

  &:disabled {
    @apply bg-gray-200;
  }

  &.expanded {
    @apply w-full;
  }

  &.default {
    @apply border-gray-300;
    @apply focus:border-primary focus-within:border-primary;
  }

  &.error {
    @apply border-danger;
    @apply focus:border-danger focus-within:border-danger;

    &:focus-visible {
      @apply outline-none ring-1 ring-danger;
    }

    &::placeholder {
      @apply text-danger opacity-60;
    }
  }
}

.textarea {
  --textarea-height: 3em;
  --textarea-max-height: 200px;

  @apply inline-block;
  @apply flex-1 bg-white;
  @apply align-baseline;
  @apply py-2.5 px-4 overflow-hidden;
  @apply overflow-y-scroll;
  @apply resize-none;
  @apply border border-solid border-gray-300;

  height: var(--textarea-height);
  max-height: var(--textarea-max-height);
  line-height: 1.5em;

  &.flexibleHeight {
    min-height: 3em;
  }

  &:focus-visible {
    @apply outline-none ring-1 ring-primary-light;
  }

  &::placeholder {
    font-style: normal !important;
  }

  &:disabled {
    @apply bg-gray-200;
  }
}

.fieldRadius {
  &-default {
    border-radius: var(--input-field-radius);
  }

  &-rounded {
    @apply rounded-3xl;
  }
}
