:root {
  --max-mobile-breakpoint: 640px;
  --max-tablet-breakpoint: 768px;
}

.loader-container {
  --overshoot-cubic-bezier: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  --logo-circle-size: 58px;
  --animation-name: hubert-right-spin;

  @apply h-screen bg-white;
  @apply flex items-center justify-center gap-8;
  @apply px-2;

  @media screen and (max-width: var(--max-mobile-breakpoint)) {
    --logo-circle-size: 38px;
  }

  .logo {
    @apply flex items-center gap-2;

    @media screen and (max-width: var(--max-mobile-breakpoint)) {
      gap: 5px;
    }
  }

  @media screen and (max-width: var(--max-mobile-breakpoint)) {
    @apply gap-4;
  }

  .hubert-right {
    width: var(--logo-circle-size);
    height: var(--logo-circle-size);
    animation: var(--animation-name) 1.5s var(--overshoot-cubic-bezier) infinite
      alternate;

    @apply rounded-full bg-black;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  .hubert-left {
    --animation-name: hubert-left-spin;

    @apply flex h-16 w-16 items-center justify-center rounded-lg bg-black;

    animation: var(--animation-name) 1.5s var(--overshoot-cubic-bezier) infinite
      alternate;

    @media screen and (max-width: var(--max-mobile-breakpoint)) {
      @apply h-10 w-10;
    }

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }

    .inner {
      width: var(--logo-circle-size);
      height: var(--logo-circle-size);

      @apply rounded-full bg-white;
    }
  }
}

@keyframes hubert-left-spin {
  from {
    transform: translate(56.5%) rotate(0deg);
  }

  to {
    transform: translate(0) rotate(-360deg);
  }
}

@keyframes hubert-right-spin {
  from {
    transform: translate(-56.5%) rotate(0deg) scale(1.01);
  }

  to {
    transform: translate(0) rotate(360deg);
  }
}
