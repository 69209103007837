.container {
  @apply flex flex-col justify-center gap-4;
  @apply p-4;
  @apply text-center;
  @apply bg-primary-neutral;
  @apply rounded-xl;
  @apply border border-solid border-primary;
}

.questionHeader {
  @apply flex flex-col items-center gap-2;
}

.updatedPill {
  @apply bg-primary;
  @apply text-white text-xs;
  @apply rounded-xl px-2 py-1;
  @apply max-w-fit;
}
