@use 'interview';
@use 'sass:math';
@use 'vars' as vars;
@use '../functions';
@import 'jarvis';

%bubble-chat-box-shared {
  @apply transition;

  border-radius: var(--element-radius);
  border-bottom-right-radius: 0;
  box-shadow: 15px 15px 24px 0 rgb(0 0 0 / 30%);
}

.chat-container {
  @apply text-sm sm:text-base w-full;
  @apply scroll-smooth duration-75;
  @apply px-4;

  min-height: var(--min-chat-height);

  & .img {
    @apply hidden p-0;

    border-radius: var(--element-radius);
  }

  &::-webkit-scrollbar {
    @apply hidden;
  }

  @screen sm {
    --static-ui-combined-height: calc(
      var(--open-question-input-height) + var(--chat-header-min-height)
    );
    --calculated-height: calc(
      var(--min-chat-height) - var(--static-ui-combined-height)
    );

    min-height: var(--calculated-height);
  }
}

.hubert-history,
.current-message-wrapper {
  @apply flex;

  .hubert-avatar {
    @apply w-6 h-6;
    @apply flex justify-center items-center self-end;
    @apply bg-gray-900 text-white rounded-full mr-2;
    @apply text-xs leading-none font-semibold;
  }
}

.hubert-history {
  @apply mb-2;
}

textarea {
  all: initial;
  text-transform: initial;
}

#input-text::placeholder {
  /* Firefox, Chrome, Opera */
  font-style: italic;
  text-transform: unset;
}

.btn::after {
  content: '';
}

.q-btn {
  max-width: 400px;

  @apply mx-auto;
}

.form-control,
.input-group,
.btn {
  border-radius: var(--input-field-radius-sm);
}

.btn {
  @apply h-9;
  @apply px-4 py-4 lg:py-0;
  @apply flex justify-center items-center gap-2;
  @apply rounded-md;

  transition: cubic-bezier(1, 0, 0, 1) 0.3s;

  &-primary {
    @apply bg-primary text-white;

    &:hover {
      @apply bg-primary-dark text-white;
    }
  }

  &-danger {
    @apply bg-danger text-white;

    &:hover {
      @apply bg-danger-dark text-white;
    }
  }

  &-tertiary {
    @apply bg-gray-900 text-white;

    &:hover {
      @apply bg-primary-light text-white;
    }
  }

  &:disabled {
    @apply bg-gray-200 text-gray-400;
  }

  svg {
    @apply w-4 h-4 text-white;
  }
}

.form-control {
  min-height: 1.5rem;

  @apply font-suisse;
  @apply align-baseline;
  @apply border border-solid border-gray-300 resize-none;
  @apply focus:border-primary focus-within:border-primary focus-visible:border-primary;
  @apply py-2.5 px-4 mr-2 overflow-hidden;
  @apply w-full;

  &::-webkit-resizer {
    @apply hidden;
  }

  &:focus-visible {
    @apply outline-none ring-1 ring-primary-light;
  }

  &::placeholder {
    font-style: normal !important;
  }

  &:disabled {
    @apply bg-gray-200;
  }
}

.interview-container-wrapper {
  @apply w-full sm:max-w-[700px] mx-auto;
  @apply max-h-[100dvh] overflow-y-hidden;
}

.messages-container {
  @apply bg-white sm:rounded-2xl;
  @apply h-[100dvh] sm:max-h-[var(--min-chat-height)] overflow-y-scroll;
}

.chat-bubble {
  @apply flex flex-col;
  
  .bubble-content {
    @apply max-w-[75dvw] sm:max-w-[420px] md:max-w-[50ch];
    @apply relative px-3 py-2 bg-gray-100 break-normal;
    @apply break-words;
    @apply cursor-pointer;

    ul,
    ol {
      @apply relative px-3 mx-1;
    }

    ul {
      list-style: disc;
    }

    ol {
      list-style: decimal;
    }

    a[data-render-type='button'] {
      @apply inline-block rounded p-[.5rem_1rem] bg-primary text-white;

      margin-top: 0;
      text-decoration: none;
    }
  }

  &.candidate-message-preview {
    @apply items-end;

    .bubble-content {
      @apply bg-primary text-white;
    }
  }

  &.hubert .bubble-content {
    @apply rounded-r-xl;

    p {
      @apply select-none;
    }
  }

  &.candidate .bubble-content {
    @apply rounded-l-xl;
    @apply bg-primary;

    color: var(--color-primary-text);
  }

  &:not(.hubert, .candidate) .bubble-content {
    @apply rounded-xl;
  }

  &.candidate {
    @apply relative mt-1 items-end;

    &:first-child {
      .bubble-content {
        @apply rounded-tr-xl;
      }
    }

    &:last-child {
      .bubble-content {
        @apply rounded-br-xl;
      }
    }
  }

  &.hubert {
    @apply relative mt-1 items-start;

    &:first-child {
      .bubble-content {
        @apply rounded-tl-xl;
      }
    }

    &:last-child {
      .bubble-content {
        @apply rounded-bl-xl;
      }
    }

    .bubble-content:has(a[data-render-type='button']) {
      @apply bg-primary rounded-lg mb-2 mt-2;
    }
  }

  &.typing {
    @apply items-start;
  }
}

.chat-bubble-fill {
  @apply min-w-[72dvw] sm:min-w-[400px] md:min-w-[48ch];

  max-width: 100%;
}
