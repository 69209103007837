.multipleSelectWrapper {
  @apply mt-5 py-3 px-2.5 bg-primary-neutral border border-primary-light w-full md:max-w-md mx-auto rounded-xl;

  .selectWrapper {
    @apply mt-4;
  }

  .selectBubbleWrapper {
    @apply flex items-end justify-end;

    .selectOption {
      @apply w-full py-1.5 bg-gray-900 rounded-xl text-white text-center text-sm md:text-base leading-normal cursor-pointer mb-1 flex justify-center items-center;

      &.selected {
        @apply bg-primary-light relative;

        svg {
          @apply absolute right-3;
        }
      }
    }

    &:last-child {
      .selectOption {
        @apply mb-0;
      }
    }
  }
}
