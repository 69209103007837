.fillGapsWrapper {
  @apply mt-5 py-4 px-5 bg-primary-neutral border border-primary-light w-full md:max-w-md mx-auto rounded-xl;

  .gapsText {
    @apply border-b border-gray-300 pb-5 mb-5 md:pb-3 md:mb-3 text-gray-500;

    span[class~="badge"] {
      @apply bg-gray-100 rounded-none mx-1 px-2 text-gray-900;
      @apply border-dotted border-b-2 border-gray-500;
    }
  }

  .gapDropdownWrapper {
    @apply flex items-center mb-4 gap-4;

    select {
      @apply flex-1 py-2.5 px-2 border border-gray-300 rounded-lg w-full;
    }

    &:last-child {
      @apply mb-0;
    }
  }
}
