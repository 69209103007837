.tip {
  @apply flex gap-4 md:gap-6;
}

.page {
  @apply gap-6 translate-y-2/4;

  header {
    @apply flex flex-col gap-4;
    @apply text-center;

    p {
      @apply font-semibold;
      @apply text-base md:text-lg;

      max-width: 50ch;
    }
  }

  footer {
    @apply gap-1;
  }
}

.tipBox {
  @apply flex flex-col gap-y-6;
  @apply w-full sm:max-w-md md:max-w-2xl;
  @apply bg-primary text-white;
  @apply rounded-lg;
  @apply px-5 py-8 md:px-10 md:py-5;
  @apply mx-auto;
  @apply text-start;

  h1 {
    @apply text-start font-semibold;
    @apply my-0;

    @screen md {
      @apply text-2xl;
    }
  }

  p {
    @apply text-sm;

    @screen md {
      @apply text-base;
    }
  }

  ul {
    @apply flex flex-col;
    @apply gap-y-4;
  }
}
