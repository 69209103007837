.formSlider {
  .unitLabel {
    @apply text-center my-3;
  }

  .rangeLabel {
    @apply flex justify-between font-semibold my-2 text-gray-500 gap-10;
    @apply text-sm;
  }
}

div[class~='rc-slider'] {
  [class~='rc-slider-track'] {
    @apply bg-primary-light;
  }

  [class~='rc-slider-handle'] {
    @apply bg-primary border-none;

    &:active {
      border-color: var(--color-primary-dark);
      box-shadow: 0 0 5px 2px var(--color-primary-light);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
  }
}
